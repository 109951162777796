var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VueSlickCarousel',_vm._b({staticClass:"product-multi-slider"},'VueSlickCarousel',_vm.productSliderOptions,false),_vm._l((_vm.productList),function(product,idx){return _c('div',{key:idx,staticClass:"pm-slider-box"},[_c('div',{staticClass:"pm-box"},[_c('b-link',{staticClass:"h-full",attrs:{"to":("/" + (product.url_key))}},[_c('div',{staticClass:"img-wrap"},[_c('div',{staticClass:"pm-img",style:(("background-image: url('" + (product.thumbnail.medium
                ? product.thumbnail.medium
                : product.thumbnail.url) + "');"))},[_c('div',{staticClass:"product-tag"},[(product.cashback_promotion)?_c('div',{staticClass:"tag"},[_c('span',{staticClass:"discount-txt"},[(
                      product.cashback_promotion.amount &&
                      product.cashback_promotion.amount.value &&
                      product.cashback_promotion.amount.value !== null
                    )?_c('span',[_vm._v(" €"+_vm._s(product.cashback_promotion.amount.value + ",-=")+" ")]):_vm._e(),(product.cashback_promotion.name)?_c('span',[_vm._v(" "+_vm._s(product.cashback_promotion.name)+" ")]):_vm._e()])]):_vm._e(),(product.labels && product.labels.length)?_c('div',{staticClass:"product-tag-wrap"},_vm._l((product.labels),function(label,idx){return _c('div',{key:idx,staticClass:"product-tag-single"},[(label.product.type === 'image')?_c('div',{staticClass:"img-label"},[_c('div',{staticClass:"tag-label",class:label.product.position,style:("background-image: url('" +
                        label.product.image_url +
                        "');" + (label.product.css))})]):_vm._e(),(label.product.type === 'text')?_c('div',{staticClass:"text-label"},[_c('div',{staticClass:"tag-label",class:label.product.position,style:(label.product.css)},[_vm._v(" "+_vm._s(label.product.text)+" ")])]):_vm._e()])}),0):_vm._e()])])])]),_c('div',{staticClass:"pm-more"},[_c('div',{staticClass:"pm-other-info"},[_c('b-link',{staticClass:"pm-card",attrs:{"to":("/" + (product.url_key))}},[_c('div',{staticClass:"pm-name"},[_vm._v(_vm._s(product.name))])]),_c('div',{attrs:{"data-bv-show":"inline_rating","data-bv-product-id":product.sku,"data-bv-redirect-url":product.url_key}}),(!product.price_range.maximum_price)?_c('span',{staticClass:"pm-price"},[_vm._v(_vm._s(_vm.formatPrice(product.price_range.minimum_price.final_price.value)))]):_vm._e(),_c('div',{staticClass:"d-flex justify-content-between",class:{ 'no-cart': !_vm.showAddToCart }},[(product.price_range.maximum_price)?_c('div',{staticClass:"price-bar"},[(
                  product.price_range.minimum_price.final_price.value ==
                  product.price_range.maximum_price.final_price.value
                )?_c('div',{staticClass:"price-bar-inner"},[(
                    product.price_range.minimum_price.discount.percent_off >=
                    5
                  )?_c('div',{staticClass:"d-flex align-items-end price-selection"},[_c('span',{staticClass:"normal-price"},[_c('span',[_vm._v(_vm._s(_vm.$t("advice_price"))+":")]),_c('span',{staticClass:"original-price"},[_c('span',{staticClass:"price-stroke"}),_vm._v(" "+_vm._s(_vm.formatPrice( product.price_range.minimum_price.regular_price .value )))])])]):_vm._e(),_c('div',{staticClass:"d-flex align-items-end price-selection"},[_c('span',{staticClass:"currentPrice"},[_vm._v(_vm._s(_vm.formatPrice( product.price_range.minimum_price.final_price.value )))])])]):_vm._e()]):_vm._e(),(
                product.price_range.maximum_price &&
                product.price_range.minimum_price.final_price.value !=
                  product.price_range.maximum_price.final_price.value
              )?_c('div',{staticClass:"d-block product-card--price"},[_vm._v(" "+_vm._s(_vm.formatPrice( product.price_range.minimum_price.final_price.value ))+" - "+_vm._s(_vm.formatPrice( product.price_range.maximum_price.final_price.value ))+" ")]):_vm._e(),(_vm.showAddToCart)?_c('div',{staticClass:"add-cart",on:{"click":function($event){return _vm.addToCart(product)}}},[_c('i',{staticClass:"fas fa-shopping-cart"})]):_vm._e()])],1)])],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }