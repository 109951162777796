<template>
  <!-- <ClientOnly> -->
  <VueSlickCarousel class="product-multi-slider" v-bind="productSliderOptions">
    <div class="pm-slider-box" v-for="(product, idx) in productList" :key="idx">
      <div class="pm-box">
        <b-link :to="`/${product.url_key}`" class="h-full">
          <!-- v-if="product.thumbnail.medium != null" -->
          <div class="img-wrap">
            <div
              class="pm-img"
              :style="`background-image: url('${
                product.thumbnail.medium
                  ? product.thumbnail.medium
                  : product.thumbnail.url
              }');`"
            >
              <div class="product-tag">
                <div class="tag" v-if="product.cashback_promotion">
                  <span class="discount-txt">
                    <span
                      v-if="
                        product.cashback_promotion.amount &&
                        product.cashback_promotion.amount.value &&
                        product.cashback_promotion.amount.value !== null
                      "
                    >
                      &euro;{{
                        product.cashback_promotion.amount.value + ",-="
                      }}
                    </span>
                    <span v-if="product.cashback_promotion.name">
                      {{ product.cashback_promotion.name }}
                    </span>
                  </span>
                </div>
                <div
                  class="product-tag-wrap"
                  v-if="product.labels && product.labels.length"
                >
                  <div
                    class="product-tag-single"
                    v-for="(label, idx) in product.labels"
                    :key="idx"
                  >
                    <div
                      v-if="label.product.type === 'image'"
                      class="img-label"
                    >
                      <div
                        class="tag-label"
                        :class="label.product.position"
                        :style="
                          `background-image: url('` +
                          label.product.image_url +
                          `');${label.product.css}`
                        "
                      />
                    </div>
                    <div
                      v-if="label.product.type === 'text'"
                      class="text-label"
                    >
                      <div
                        class="tag-label"
                        :class="label.product.position"
                        :style="label.product.css"
                      >
                        {{ label.product.text }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-link>

        <div class="pm-more">
          <div class="pm-other-info">
            <b-link :to="`/${product.url_key}`" class="pm-card"
              ><div class="pm-name">{{ product.name }}</div>
            </b-link>

            <div
              data-bv-show="inline_rating"
              :data-bv-product-id="product.sku"
              :data-bv-redirect-url="product.url_key"
            ></div>

            <!-- <Rating
              :stars="product.rating_summary"
              :noOfRatings="product.review_count"
            /> -->

            <span class="pm-price" v-if="!product.price_range.maximum_price">{{
              formatPrice(product.price_range.minimum_price.final_price.value)
            }}</span>

            <div
              class="d-flex justify-content-between"
              :class="{ 'no-cart': !showAddToCart }"
            >
              <div class="price-bar" v-if="product.price_range.maximum_price">
                <div
                  class="price-bar-inner"
                  v-if="
                    product.price_range.minimum_price.final_price.value ==
                    product.price_range.maximum_price.final_price.value
                  "
                >
                  <!-- v-if="
                      product.price_range.minimum_price.regular_price.value >
                      product.price_range.minimum_price.final_price.value
                    " -->
                  <div
                    v-if="
                      product.price_range.minimum_price.discount.percent_off >=
                      5
                    "
                    class="d-flex align-items-end price-selection"
                  >
                    <span class="normal-price">
                      <span>{{ $t("advice_price") }}:</span>
                      <span class="original-price">
                        <span class="price-stroke"></span>
                        {{
                          formatPrice(
                            product.price_range.minimum_price.regular_price
                              .value
                          )
                        }}</span
                      ></span
                    >
                  </div>

                  <div class="d-flex align-items-end price-selection">
                    <span class="currentPrice">{{
                      formatPrice(
                        product.price_range.minimum_price.final_price.value
                      )
                    }}</span>
                  </div>
                </div>
              </div>

              <div
                v-if="
                  product.price_range.maximum_price &&
                  product.price_range.minimum_price.final_price.value !=
                    product.price_range.maximum_price.final_price.value
                "
                class="d-block product-card--price"
              >
                {{
                  formatPrice(
                    product.price_range.minimum_price.final_price.value
                  )
                }}
                -
                {{
                  formatPrice(
                    product.price_range.maximum_price.final_price.value
                  )
                }}
              </div>
              <div
                class="add-cart"
                @click="addToCart(product)"
                v-if="showAddToCart"
              >
                <i class="fas fa-shopping-cart"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </VueSlickCarousel>
  <!-- </ClientOnly> -->
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

//import Rating from "@/base/core/components/products/category-product/Rating";
import { Logger } from "@storefront/core/lib/logger";
// import ClientOnly from "vue-client-only";
import config from "@config";

export default {
  name: "ProductSlider",
  components: {
    VueSlickCarousel,
    // Rating,
    // ClientOnly,
  },
  props: {
    productList: Array,
    showAddToCart: Boolean,
    rows: Number,
    productToShow: Number,
    infinite: Boolean,
    arrows: Boolean,
    dots: Boolean,
    sliderResponsive: { Array, required: false },
  },
  data() {
    return {
      catIdTrade: null,
      productSliderOptions: {
        arrows: this.arrows,
        dots: true,
        slidesToShow: this.productToShow,
        rows: this.rows,
        infinite: this.infinite,
        responsive: this.sliderResponsive,
      },
    };
  },
  created() {
    this.catIdTrade = config.tradeInPromotion;
  },
  methods: {
    async addToCart(product) {
      Logger.debug("addToCart", "ProductSlider", product)();
      switch (product.__typename) {
        case "SimpleProduct": {
          const item =
            '{data: {sku: "' +
            product.sku +
            '", quantity:' +
            1 +
            // this.quantity +
            "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: product.__typename,
            item: item,
          });
          Logger.debug("retval", "addToCart", retval)();
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("shopping_basket"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            const msg = {
              type: "success",
              title: "",
              text: this.$t("items_added_to_cart", { numberOf: 1 }),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          }
          break;
        }
        case "ConfigurableProduct": {
          this.$router.push(product.url);
          break;
        }
        default:
          break;
      }
    },
    formatPrice(price) {
      if (price % 1 === 0) {
        return "€" + price + ",-";
      }
      return "€" + price.toFixed(2).replace(".", ",");
    },
  },
};
</script>
<style lang="scss">
@import "@/base/scss/variables.scss";

.product-multi-slider {
  padding: 0px 0 20px 0px;
  .slick-list {
    margin: 0 -10px !important;
  }
  .pm-slider-box {
    padding: 10px;
    .pm-box {
      position: relative;
      border: 1px solid $borderLightColor;
      border-radius: $borderRadius;
      transition: $transition;
      .img-wrap {
        height: 160px;
        padding: 15px 15px 0px;
        position: relative;
        .pm-img {
          background-size: contain;
          height: 100%;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      &:hover {
        box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
      }
      .pm-more {
        .pm-other-info {
          padding: 15px;
          text-align: center;
          .pm-card {
            .pm-name {
              font-size: 18px;
              font-weight: 600;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              line-height: 22px;
              min-height: 45px;
              margin-bottom: 10px;
            }
          }
          .rating {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            .no-of-ratings {
              font-size: 12px;
              line-height: normal;
              margin-left: 5px;
            }
          }
          .pm-price {
            font-weight: 600;
            font-size: 20px;
            margin-top: 5px;
          }
        }
      }
    }
    .no-cart {
      justify-content: center !important;
      .price-bar {
        .price-bar-inner {
          display: flex;
          justify-content: center;
          align-items: center;
          .price-selection {
            margin: 0 5px;
            .currentPrice {
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .slick-dots {
    bottom: -8px;
    li {
      width: auto;
      height: auto;
      margin: 0;
      button {
        padding: 0;
        background-color: $secondaryColor;
        height: 3px;
        width: 25px;
        margin: 0 3px;
        &::before {
          display: none;
        }
      }
      &.slick-active {
        button {
          background-color: $primaryColor;
        }
      }
    }
  }
  .slick-arrow {
    &::before {
      color: $secondaryColor;
    }
    &.slick-next,
    &.slick-prev {
      transform: translateY(0);
      width: auto;
      height: auto;
      padding: 12px;
      &::before,
      &::after {
        content: "";
        border-right: 2px solid;
        display: block;
        height: 12px;
        margin-top: -10px;
        position: absolute;
        transform: rotate(135deg);
        left: 10px;
        top: 50%;
        width: 0;
        padding: 0;
        color: $secondaryColor;
        opacity: 1;
        z-index: 10;
      }
      &:after {
        margin-top: -2px;
        transform: rotate(45deg);
      }
    }
    &.slick-prev {
      transform: rotate(180deg);
      right: 70px;
      top: 1px;
      left: auto;
    }
    &.slick-next {
      right: 4px;
      top: 0;
    }
  }
}
</style>
