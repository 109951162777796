<template>
  <div>
    <div v-if="!viewOrder" class="account-order">
      <div class="title">
        <h3>{{ $t("my_orders") }}</h3>
      </div>
      <div class="account-order-wrap">
        <label class="no-order" v-if="addressList && addressList.length === 0">
          {{ $t("no_orders_available") }}
        </label>
        <template v-if="addressList && addressList.length > 0">
          <b-table
            class="account-order-table"
            responsive
            hover
            :items="addressList"
            :fields="fields"
            :small="true"
            v-if="!mobileCheck"
          >
            <template v-slot:cell(action)="data">
              <div class="order-btn-action">
                <b-link @click="showOrder(data.item)" class="link">{{
                  $t("view_order")
                }}</b-link>
              </div>
            </template>
          </b-table>
          <div v-if="mobileCheck" class="mob-myorderswrap">
            <div
              class="d-flex justify-content-between mob-myorders"
              v-for="order in addressList"
              :key="order.id"
            >
              <div class="myorder-details-wrap">
                <div class="myorder-details">
                  <div class="myorder-key">{{ $t("number") }}:</div>
                  <div class="value">{{ order.number }}</div>
                </div>
                <div class="myorder-details">
                  <div class="myorder-key">{{ $t("status") }}:</div>
                  <div class="value">{{ order.status }}</div>
                </div>
                <div class="myorder-details">
                  <div class="myorder-key">{{ $t("order_date") }}:</div>
                  <div class="value">{{ convertDate(order.order_date) }}</div>
                </div>
                <div class="myorder-details">
                  <div class="myorder-key">{{ $t("total_amount") }}:</div>
                  <div class="value">
                    {{ formatPrice(order.total.base_grand_total.value) }}
                  </div>
                </div>
              </div>
              <div class="order-btn-action">
                <b-link @click="showOrder(order)" class="link">{{
                  $t("view_order")
                }}</b-link>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="order-view-section" v-if="viewOrder && OrderDetail">
      <div class="title">
        <h3>{{ $t("info_your_order") }}</h3>
        <div class="bottom">
          <p>{{ $t("your_order_number") }}: {{ OrderDetail.number }}</p>
          <div class="action">
            <b-link to="">{{ $t("write_review") }}</b-link>
            <b-link to="">{{ $t("download_invoice") }}</b-link>
          </div>
        </div>
      </div>
      <div class="order-view-wrap">
        <div
          class="single-order-view"
          v-for="product in OrderDetail.items"
          :key="product.product_sku"
        >
          <!-- {{OrderDetail}}  -->
          <div class="img-wrap">
            <div
              class="img"
              :style="`background-image: url('${product.image.medium}');`"
            ></div>
          </div>
          <div class="content-wrap">
            <div class="left">
              <h3>{{ product.product_name }}</h3>
              <ul>
                <li>
                  <label>{{ $t("number") }}:</label>
                  <p>{{ 1 }}</p>
                </li>
                <li>
                  <label>{{ $t("status") }}:</label>
                  <p>{{ OrderDetail.status }}</p>
                </li>
                <li>
                  <label>{{ $t("order_date") }}:</label>
                  <p>{{ convertDate(OrderDetail.order_date) }}</p>
                </li>
              </ul>
            </div>
            <div class="right">
              <label
                >{{ formatPrice(product.product_sale_price.value) }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="total">
        <label>{{ $t("total_amount") }}:</label>
        <p>{{ formatPrice(OrderDetail.total.base_grand_total.value) }}</p>
      </div>
      <div class="action-wrap">
        <b-link @click="showOrderList"
          ><b-icon icon="chevron-left"></b-icon>
          {{ $t("back_to_overview") }}</b-link
        >
        <b-btn class="btn-gray">{{ $t("order_again") }}</b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile, isTablet } from "mobile-device-detect";
export default {
  name: "AccountOrders",
  data() {
    return {
      viewOrder: false,
      OrderDetail: null,
      selectedOption: 10,
      options: [10, 20, 30],
      fields: [
        {
          key: "number",
          label: this.$t("order_number"),
        },
        {
          key: "status",
          label: this.$t("status"),
          formatter: (value) => value,
        },
        {
          key: "order_date",
          label: this.$t("order_date"),
          formatter: (value) => this.convertDate(value),
        },
        {
          key: "grand_total",
          label: this.$t("grand_total"),
          formatter: (value, key, item) =>
            this.formatPrice(item.total.base_grand_total.value),
        },
        {
          key: "action",
          label: "",
        },
      ],
    };
  },
  computed: {
    mobileCheck() {
      if (isMobile || isTablet) {
        return true;
      }
      return false;
    },
    addressList() {
      return this.$store.getters["user/getMyOrders"];
    },
  },
  mounted() {
    this.$store.dispatch("user/loadOrders");
  },
  methods: {
    showOrder(data) {
      this.viewOrder = true;
      this.OrderDetail = data;
    },
    showOrderList() {
      this.OrderDetail = null;
      this.viewOrder = false;
    },
    convertDate(date) {
      let reviewDate = new Date(date);
      let dd = reviewDate.getDate();
      let mm = reviewDate.getMonth() + 1;
      let yyyy = reviewDate.getFullYear();
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) dd = "0" + mm;
      reviewDate = dd + "-" + mm + "-" + yyyy;
      return reviewDate;
    },
    formatPrice(price) {
      if (price % 1 === 0) {
        return "€" + price + ",-";
      }
      return "€" + price.toFixed(2).replace(".", ",");
    },
  },
};
</script>
<style lang="scss" scoped></style>
