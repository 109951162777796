<template>
  <div>
    <Breadcrumbs />
    <div class="account-page">
      <b-container>
        <b-tabs
          pills
          card
          vertical
          v-model="tabIndex"
          nav-wrapper-class="account-tab"
        >
          <b-tab :title="$t('my_account')"><AccountMyAccount /> </b-tab>
          <b-tab :title="$t('my_orders')"
            ><b-card-text><AccountOrders /></b-card-text
          ></b-tab>
          <b-tab :title="$t('my_wishlist')"
            ><b-card-text><AccountWishlist /></b-card-text
          ></b-tab>
          <b-tab :title="$t('directory')">
            <AccountAddressBook />
          </b-tab>
          <b-tab :title="$t('contact_detail')">
            <AccountInformation />
          </b-tab>
          <b-tab :title="$t('change_email')">
            <AccountEmail />
          </b-tab>
          <b-tab :title="$t('change_password')">
            <AccountPassword />
          </b-tab>
          <b-tab :title="$t('product_review')">
            <AccountReviews />
          </b-tab>
          <b-tab :title="$t('newsletter')">
            <NewsletterSubscription />
          </b-tab>
          <b-tab @click="goLogout" :title="'Uitloggen'"> </b-tab>
        </b-tabs>
      </b-container>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/base/core/components/common/Breadcrumbs";
import AccountInformation from "@/base/core/components/account/AccountInformation";
import AccountEmail from "@/base/core/components/account/AccountEmail";
import AccountPassword from "@/base/core/components/account/AccountPassword";
import AccountMyAccount from "@/base/core/components/account/AccountMyAccount";
import AccountAddressBook from "@/base/core/components/account/AccountAddressBook";
import NewsletterSubscription from "@/base/core/components/account/NewsletterSubscription";
import AccountWishlist from "@/base/core/components/account/AccountWishlist";
import AccountOrders from "@/base/core/components/account/AccountOrders";
import AccountReviews from "@/base/core/components/account/AccountReviews";

export default {
  name: "Account",
  components: {
    Breadcrumbs,
    AccountInformation,
    AccountEmail,
    AccountPassword,
    AccountAddressBook,
    AccountMyAccount,
    NewsletterSubscription,
    AccountWishlist,
    AccountOrders,
    AccountReviews,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        if (val < 9) this.$store.commit("user/setAccountTabIndex", val);
      },
    },
  },
  watch: {
    /*
    isLoggedIn(newVal, oldVal) {
      if (this.$store.getters["user/getIsLoggedIn"] == false) {
        this.$router.push({ name: "login" });
      }
    },
    */
  },
  mounted() {
    const bcrumb = { current: this.$t("account"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);

    /*
    if (this.$store.getters["user/getIsLoggedIn"] == false) {
      this.$router.push({ name: "login" });
    }
    */
  },
  data() {
    return {
      statusAddForm: true,
      thanksActive: false,
      form: {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
        input8: "",
        input9: "",
        checkbox2: false,
      },
    };
  },
  methods: {
    formSubmit() {
      this.thanksActive = true;
    },
    formSubmitValidate() {},
    goLogout() {
      this.visible = false;
      this.$store.dispatch("user/logout");
      this.$store.commit("user/setAccountTabIndex", 0);
      this.$router.push("/");
    },
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { name: "title", content: this.$t("my_account") },
        { name: "keywords", content: this.$t("my_account") },
        { name: "description", content: this.$t("my_account") },
      ],
      title: this.$t("my_account"),
    };
  },
};
</script>

<style lang="scss"></style>
