<template>
  <div class="product-box">
    <!-- AddToCompare Modal -->
    <b-modal
      v-if="comparePopupProduct"
      id="addToCompare"
      title=""
      size="lg"
      content-class="product-popup product-compare-popup"
      dialog-class="cart-modal"
      centered
      v-model="addToComparePopup"
      :hide-footer="true"
    >
      <b-row class="add-to-cart-popup">
        <b-col col lg="9">
          <div class="title mb-3">
            <h4>{{ $t("compare_popup_title") }}</h4>
          </div>
          <div class="addToCart-wrap mb-4">
            <div class="cart-product-details">
              <div class="image">
                <img :src="comparePopupProduct.thumbnail.url" />
                <div class="cart-product-name">
                  {{ comparePopupProduct.name }}
                </div>
              </div>
              <div class="cart-product-price">
                {{
                  formatPrice(
                    comparePopupProduct.price_range.maximum_price.final_price
                      .value
                  )
                }}
              </div>
            </div>
          </div>
          <div class="cart-buttons-wrap d-flex justify-content-between">
            <button class="btn btn-primary" @click="clickOk">
              {{ $t("compare") }}
            </button>
            <button class="btn btn-gray" @click="clickCancel">
              {{ $t("continue_shoping") }}
            </button>
          </div>
        </b-col>
        <b-col lg="3">
          <div class="person-img">
            <img src="@/base/assets/add_to_cart_popup.png" alt="" />
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <b-link :to="`/${product.product.url_key}`" class="h-full">
      <div class="img-wrap" v-if="product.product.thumbnail.url != null">
        <div
          class="product-img"
          :style="
            `background-image: url('` + product.product.thumbnail.url + `');`
          "
        >
          <div class="product-tag">
            <div class="tag">
              <span class="new-txt" v-if="onNew == true">{{ $t("New") }}</span>
            </div>
            <div class="tag">
              <span class="sale-txt" v-if="onSale == true">{{
                $t("Sale")
              }}</span>
            </div>
          </div>
        </div>

        <!-- <VueLazyBackgroundImage
          :image-source="product.product.thumbnail.url"
          image-class="product-card--img-top"
          errorImage="../../assets/logo.jpg"
          loadingImage="../../assets/logo.jpg"
          style="background-size: contain"
        >
          <div class="actionbuttons">
            <span class="sale-txt" v-if="onSale == true">sale</span>
            <span class="sale-txt" v-if="onNew == true">new</span>
          </div>
        </VueLazyBackgroundImage> -->
      </div>
    </b-link>
    <div class="product-more">
      <div class="product-other-info">
        <b-link :to="`/${product.product.url_key}`" class="product-card"
          ><div class="product-name">{{ product.product.name }}</div>
        </b-link>

        <ul
          v-for="attr of product.configurable_options"
          :key="attr.id"
          class=""
        >
          <li v-for="opt of attr.values" :key="opt.value_index">
            {{ opt.label }}
          </li>
        </ul>
        <div class="product-rating">
          <div
            data-bv-show="inline_rating"
            :data-bv-product-id="product.product.sku"
            :data-bv-redirect-url="product.product.url_key"
          ></div>
        </div>
        <div class="product-short-info">
          <div
            v-html="
              product.product.description.html
                .replace(/<\/?[^>]+(>|$)/g, '')
                .substring(0, 120) + '...'
            "
          ></div>
        </div>
        <div class="product-action-bar">
          <div class="remove">
            <div class="remove-btn">
              <b-icon
                icon="trash"
                @click.prevent="removeItem(product.id)"
              ></b-icon>
              <span>{{ $t("delete_from_list") }}</span>
            </div>
          </div>
          <div class="compare">
            <b-form-checkbox
              label-for="compare"
              :checked="isInCompare(product.product.sku)"
              @change="changeCompareStatus($event, product.product.sku)"
              >{{ $t("compare") }}</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <div class="price-bar">
        <span class="product-price">{{
          formatPrice(
            product.product.price_range.minimum_price.final_price.value
          )
        }}</span>
        <div class="price-ship-info">
          <div class="p-ship">
            <b-icon icon="check-circle-fill"></b-icon>
            <span>Morgen in huis</span>
          </div>
          <!-- <div class="p-store">
            <label>Nog sneller op te halen in 8 winkels!</label>
          </div> -->
        </div>
        <div class="add-cart" @click="addToCart">
          <b-link class="btn btn-primary w-full">
            {{ $t("order_now") }}
            <i class="fas fa-shopping-cart"></i>
          </b-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Logger } from "@storefront/core/lib/logger";
// import { Logger } from "@storefront/core/lib/logger";
// import VueLazyBackgroundImage from "@/base/core/components/core/VueLazyBackgroundImage";

export default {
  name: "ProductCardWishlist",
  props: {
    product: {
      type: Object,
      require: true,
    },
  },
  components: {
    // VueLazyBackgroundImage,
  },
  data() {
    return {
      addToComparePopup: false,
      comparePopupProduct: null,
    };
  },
  computed: {
    compareList() {
      return this.$store.getters["product/getProductCompare"];
    },
    onSale() {
      return false;
      // const today = new Date();
      // if (this.product.special_from_date != null) {
      //   if (new Date(this.product.special_from_date) <= today) {
      //     if (new Date(this.product.special_to_date) >= today) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   } else {
      //     return false;
      //   }
      // } else {
      //   return false;
      // }
    },
    onNew() {
      return false;
      // const today = new Date();
      // if (this.product.new_from_date != null) {
      //   if (new Date(this.product.new_from_date) <= today) {
      //     if (this.product.new_to_date != null) {
      //       if (new Date(this.product.new_to_date) >= today) {
      //         return true;
      //       } else {
      //         return false;
      //       }
      //     } else {
      //       return true;
      //     }
      //   } else {
      //     return false;
      //   }
      // } else {
      //   return false;
      // }
    },
  },
  created() {},
  methods: {
    clickOk() {
      this.addToComparePopup = false;
      this.comparePopupProduct = null;
      this.$router.push("/compare");
    },
    clickCancel() {
      this.addToComparePopup = false;
      this.comparePopupProduct = null;
    },
    isInCompare(sku) {
      if (this.compareList) {
        return !!this.compareList.find((product) => product.sku === sku);
      }
      return false;
    },
    changeCompareStatus(event, sku) {
      if (event && !this.isInCompare(sku)) {
        this.addToCompare(sku);
      } else {
        this.removeFromCompare(sku);
      }
    },
    removeItem(id) {
      this.$store.dispatch("user/removeProductWishlist", {
        id: id,
      });
    },
    addToCart() {
      this.$router.push(this.product.product.url_key);
    },

    async addToCompare(sku) {
      let retval = await this.$store.dispatch("product/addCompareProduct", {
        sku,
      });
      Logger.debug("retval", "addToCompare", retval)();
      if (retval == false) {
        const msg = {
          type: "danger",
          title: "",
          text: "Unable to add",
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      } else {
        this.comparePopupProduct = this.compareList.find(
          (prod) => prod.sku === sku
        );
        this.addToComparePopup = true;
      }
    },
    async removeFromCompare(sku) {
      let retval = await this.$store.commit(
        "product/removeProductFromCompare",
        sku
      );
      Logger.debug("retval", "removeFromCompare", retval)();
      if (retval == false) {
        const msg = {
          type: "danger",
          title: "",
          text: "something went wrong",
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
    formatPrice(price) {
      if (price % 1 === 0) {
        return "€" + price + ",-";
      }
      return "€" + price.toFixed(2).replace(".", ",");
    },
  },
};
</script>

<style lang="scss" scoped></style>
