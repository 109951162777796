<template>
  <div class="account-wishlist">
    <div class="title">
      <h3>{{ $t("wishlist") }}</h3>
    </div>
    <div class="account-wishlist-wrap fancy-scroll">
      <div
        class="account-wishlist-row"
        v-for="product of wishlistItems"
        :key="product.id"
      >
        <ProductCardWishlist :product="product" />
      </div>
    </div>
  </div>
</template>

<script>
import Cart from "@storefront/core/modules/cart/mixins";
import ProductCardWishlist from "@/base/core/components/account/ProductCardWishlist";
export default {
  name: "AccountWishlist",
  mixins: [Cart],
  components: { ProductCardWishlist },
  methods: {
    removeItem(id) {
      this.$store.dispatch("user/removeProductWishlist", {
        id: id,
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
