<template>
  <div class="account-address-book">
    <div class="title">
      <h3>{{ $t("newsletter_subscriptions") }}</h3>
    </div>
    <div class="address-block">
      <!-- <h3 class="semiBold-18">{{ $t("newsletter_subscription_option") }}</h3> -->
      <b-row>
        <b-col sm="6">
          <b-form-checkbox
            id="checkbox-1"
            name="checkbox-1"
            label-for="checkbox1"
            v-model="is_subscribed"
            >{{ $t("newsletter_subscription_selected") }}</b-form-checkbox
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <div class="form-footer-wrap">
            <div class="form-footer">
              <b-button @click="save" variant="primary" class="">{{
                $t("save")
              }}</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "NewsletterSubscription",
  components: {},
  mounted() {
    Logger.debug("user", "NewsletterSubscription", this.user.is_subscribed)();
    this.is_subscribed = this.user.is_subscribed;
    Logger.debug(
      "this.is_subscribed",
      "NewsletterSubscription",
      this.is_subscribed
    )();
  },
  data() {
    return {
      is_subscribed: false,
    };
  },
  computed: {
    getFilter() {
      return [1, 2, 3, 4, 5, 6];
    },
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
  },
  methods: {
    save() {
      this.$store.dispatch("user/updateUserNewsletter", {
        is_subscribed: this.is_subscribed,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
