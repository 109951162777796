<template>
  <div class="account-review">
    <div class="title">
      <h3>{{ $t("product_review") }}</h3>
    </div>
    <div class="account-review-wrap">
      <div class="account-review-single">
        <div class="name">
          <label>Hisense 4K OLED Smart TV TVH55U8QF</label>
        </div>
        <div class="rating">
          <!-- <Rating
            :stars="product.rating_summary"
            :noOfRatings="product.review_count"
          /> -->
          <ul class="ratings-star d-flex">
            <li class="text-gray"><i class="fas fa-star"></i></li>
            <li class="text-gray"><i class="fas fa-star"></i></li>
            <li class="text-gray"><i class="fas fa-star"></i></li>
            <li class="text-gray"><i class="fas fa-star"></i></li>
            <li class="text-gray"><i class="fas fa-star"></i></li>
          </ul>
        </div>
        <div class="date">
          <p>01-01-2021</p>
        </div>
        <div class="to-review">
          <p>Naar review</p>
        </div>
      </div>
      <div class="account-review-single">
        <div class="name">
          <label>Hisense 4K OLED Smart TV TVH55U8QF</label>
        </div>
        <div class="rating">
          <!-- <Rating
            :stars="product.rating_summary"
            :noOfRatings="product.review_count"
          /> -->
          <ul class="ratings-star d-flex">
            <li class="text-gray"><i class="fas fa-star"></i></li>
            <li class="text-gray"><i class="fas fa-star"></i></li>
            <li class="text-gray"><i class="fas fa-star"></i></li>
            <li class="text-gray"><i class="fas fa-star"></i></li>
            <li class="text-gray"><i class="fas fa-star"></i></li>
          </ul>
        </div>
        <div class="date">
          <p>01-01-2021</p>
        </div>
        <div class="to-review">
          <p>Naar review</p>
        </div>
      </div>
      <div class="account-review-single">
        <div class="name">
          <label>Hisense 4K OLED Smart TV TVH55U8QF</label>
        </div>
        <div class="rating">
          <!-- <Rating
            :stars="product.rating_summary"
            :noOfRatings="product.review_count"
          /> -->
          <ul class="ratings-star d-flex">
            <li class="text-gray"><i class="fas fa-star"></i></li>
            <li class="text-gray"><i class="fas fa-star"></i></li>
            <li class="text-gray"><i class="fas fa-star"></i></li>
            <li class="text-gray"><i class="fas fa-star"></i></li>
            <li class="text-gray"><i class="fas fa-star"></i></li>
          </ul>
        </div>
        <div class="date">
          <p>01-01-2021</p>
        </div>
        <div class="to-review">
          <p>Naar review</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Rating from "@/base/core/components/products/category-product/Rating.vue";

export default {
  name: "AccountReviews",
  components: {
    // Rating,
  },
};
</script>

<style scoped></style>