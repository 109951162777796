<template>
  <section class="breadcrumbs" v-if="!mobileCheck">
    <div class="container">
      <b-breadcrumb :items="items"></b-breadcrumb>
    </div>
  </section>
</template>

<script>
import { isMobile, isTablet } from "mobile-device-detect";
export default {
  name: "Breadcrumbs",
  computed: {
    items() {
      const items = [];
      const bcrumbs = this.$store.getters["breadcrumbs/getBreadcrumbsRoutes"];
      let item = { text: "Home", to: "/" };
      items.push(item);
      bcrumbs.forEach((bcrumb) => {
        item = { text: bcrumb.name, to: bcrumb.route_link };
        items.push(item);
      });
      const curr = this.$store.getters["breadcrumbs/getBreadcrumbsCurrent"];
      item = { text: curr, active: true };
      items.push(item);
      return items;
    },
    mobileCheck() {
      if (isMobile || isTablet) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/base/scss/variables.scss";
.breadcrumb {
  background-color: transparent;
  padding: 15px 0px;
  font-size: 12px;
  margin-bottom: 0;
  li {
    color: $secondaryColor;
    a {
      color: $secondaryColor;
    }
    + li {
      position: relative;
      padding-left: 20px !important;
      &:before,
      &:after {
        border-right: 2px solid;
        content: "";
        display: block;
        height: 7px;
        margin-top: -7px;
        position: absolute;
        transform: rotate(135deg);
        left: 10px;
        top: 50%;
        width: 0;
        padding: 0;
        color: currentColor;
      }
      &::before {
        content: "" !important;
        padding-right: 0 !important;
      }
      &:after {
        margin-top: -2px;
        transform: rotate(45deg);
      }
    }
  }
  @media (max-width: 767px) {
    display: none;
  }
}
</style>
