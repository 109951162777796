<template>
  <div>
    <div v-if="editAddressId == 0">
      <div class="manage-address">
        <div class="title-with-sub">
          <h3>
            {{ $t("directory") }}
          </h3>
          <p>
            {{
              $t(
                "address_book_page_you_can_add_one_or_more_addresses_to_your_account"
              )
            }}
          </p>
          <b-button
            @click="newAddress()"
            type="button"
            variant="primary"
            class=""
            >{{ $t("add_new_address") }}</b-button
          >
        </div>

        <div class="address-block">
          <b-row>
            <b-col lg="4" md="6" sm="6">
              <div class="single-address">
                <div class="single-address-inner">
                  <h5>{{ $t("default_billing_address") }}</h5>
                  <div class="no_info" v-if="defaultBilling == null">
                    {{ $t("no_default_billing_address") }}
                  </div>
                  <address v-if="defaultBilling != null">
                    <span
                      >{{ defaultBilling.firstname }}
                      {{ defaultBilling.lastname }}</span
                    >
                    <span v-if="defaultBilling.company != null">{{
                      defaultBilling.company
                    }}</span>
                    <span>{{ defaultBilling.street[0] }}</span>
                    <span
                      >{{ defaultBilling.city }},
                      {{ defaultBilling.postcode }}</span
                    >
                    <span>{{ defaultBilling.country_code }}</span>
                    <span
                      >T:<a :href="`tel:` + defaultBilling.telephone">
                        {{ defaultBilling.telephone }}</a
                      ></span
                    >
                    <span v-if="defaultBilling.vat_id != null"
                      >VAT:{{ defaultBilling.vat_id }}</span
                    >
                  </address>
                </div>
                <div class="wrap">
                  <b-link
                    class="link"
                    @click="goToEditAddress(defaultBilling.id)"
                    >{{ $t("edit_address") }}</b-link
                  >
                  <b-link class="remove">
                    <b-icon icon="trash"></b-icon
                  ></b-link>
                </div>
              </div>
            </b-col>
            <b-col lg="4" md="6" sm="6">
              <div class="single-address">
                <div class="single-address-inner">
                  <h5>{{ $t("default_delivery_address") }}</h5>
                  <div class="no_info" v-if="defaultShipping == null">
                    {{ $t("default_delivery_address") }}
                  </div>
                  <address v-if="defaultShipping != null">
                    <span
                      >{{ defaultShipping.firstname }}
                      {{ defaultShipping.lastname }}</span
                    >
                    <span v-if="defaultShipping.company != null">{{
                      defaultShipping.company
                    }}</span>
                    <span>{{ defaultShipping.street[0] }}</span>
                    <span
                      >{{ defaultShipping.city }},
                      {{ defaultShipping.postcode }}</span
                    >
                    <span>{{ defaultShipping.country_code }}</span>
                    <span
                      >T:<a :href="`tel:` + defaultShipping.telephone">
                        {{ defaultShipping.telephone }}</a
                      ></span
                    >
                    <span v-if="defaultShipping.vat_id != null"
                      >VAT:{{ defaultShipping.vat_id }}</span
                    >
                  </address>
                </div>
                <div class="wrap">
                  <b-link
                    class="link"
                    @click="goToEditAddress(defaultShipping.id)"
                    >{{ $t("edit_address") }}</b-link
                  >
                  <b-link class="remove">
                    <b-icon icon="trash"></b-icon
                  ></b-link>
                </div>
              </div>
            </b-col>
            <b-col
              lg="4"
              md="6"
              sm="6"
              v-for="address in addressList"
              :key="address.id"
            >
              <div class="single-address">
                <div class="single-address-inner">
                  <h5 class="invisible">
                    {{ $t("default_delivery_address") }}
                  </h5>
                  <address v-if="address != null">
                    <span>{{ address.firstname }} {{ address.lastname }}</span>
                    <span v-if="address.company != null">{{
                      address.company
                    }}</span>
                    <span>{{ address.street[0] }}</span>
                    <span>{{ address.city }}, {{ address.postcode }}</span>
                    <span>{{ address.country_code }}</span>
                    <span
                      >T:<a :href="`tel:` + address.telephone">
                        {{ address.telephone }}</a
                      ></span
                    >
                    <span v-if="address.vat_id != null"
                      >VAT:{{ address.vat_id }}</span
                    >
                  </address>
                </div>
                <div class="wrap">
                  <b-link class="link" @click="goToEditAddress(address.id)">{{
                    $t("edit_address")
                  }}</b-link>
                  <b-link class="remove">
                    <b-icon icon="trash"></b-icon
                  ></b-link>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <AccountEditAddress v-if="editAddressId != 0" />
  </div>
</template>

<script>
import AccountEditAddress from "@/base/core/components/account/AccountEditAddress";

export default {
  name: "AccountAddressBook",
  components: { AccountEditAddress },
  data() {
    return {
      selectedOption: 10,
      options: [10, 20, 30],
      fields: [
        {
          key: "firstname",
          label: this.$t("form_first_name"),
        },
        {
          key: "lastname",
          label: this.$t("form_last_name"),
        },
        {
          key: "street[0]",
          label: this.$t("form_street"),
        },
        {
          key: "city",
          label: this.$t("form_city"),
        },
        {
          key: "country_code",
          label: this.$t("form_country"),
        },
        {
          key: "postcode",
          label: this.$t("form_postal_code"),
        },
        {
          key: "telephone",
          label: this.$t("form_phone"),
        },
        {
          key: "btn",
          label: "",
        },
      ],
    };
  },
  computed: {
    getFilter() {
      return [1, 2, 3, 4, 5, 6];
    },
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    editAddressId: {
      get() {
        return this.$store.getters["user/getEditAddressId"];
      },
      set(val) {
        this.$store.commit("user/setEditAddressId", val);
      },
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    defaultBilling() {
      return this.$store.getters["user/getDefaultBillingAddress"];
    },
    defaultShipping() {
      return this.$store.getters["user/getDefaultShippingAddress"];
    },
    addressList() {
      if (typeof this.user.addresses != "undefined") {
        const addresses = this.user.addresses;
        const addr = [];
        addresses.forEach((element) => {
          const btn = {
            label: this.$t("edit"),
            label2: this.$t("delete"),
          };
          element.btn = btn;
          addr.push(element);
        });
        return addr;
      }
      return [];
    },
  },

  methods: {
    newAddress() {
      this.editAddressId = -1;
    },
    Adjust(id) {
      this.editAddressId = id;
    },
    Remove(id) {
      this.$store.dispatch("user/deleteAddress", { id: id });
    },
    goToEditAddress(id) {
      this.tabIndex = 3;
      this.$store.commit("user/setEditAddressId", id);
    },
    goToEditPassword() {
      this.tabIndex = 4;
    },
  },
};
</script>

<style lang="scss" scoped></style>
