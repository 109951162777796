<template>
  <div class="account-address-book">
    <div class="title">
      <h3 v-if="editAddressId > 0">{{ $t("edit_address") }}</h3>
      <h3 v-if="editAddressId == -1">{{ $t("add_new_address") }}</h3>
    </div>
    <div class="address-block">
      <b-row>
        <b-col md="6">
          <b-form-group
            class="account-inputs"
            id="first-name-group"
            :label="$t('form_first_name') + '*'"
            label-for="first_name"
          >
            <b-form-input
              id="first_name"
              v-model="form.firstname"
              type="text"
              v-on:blur="validateFirstname()"
              :state="form.firstnameState"
              aria-describedby="firstname-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="firstname-feedback">
              {{ form.firstnameError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            class="account-inputs"
            id="last-name-group"
            :label="$t('form_last_name') + '*'"
            label-for="last_name"
          >
            <b-form-input
              id="last_name"
              v-model="form.lastname"
              type="text"
              v-on:blur="validateLastname()"
              :state="form.lastnameState"
              aria-describedby="lastname-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="lastname-feedback">
              {{ form.lastnameError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            class="account-inputs"
            id="telephone-group"
            :label="$t('form_phone') + '*'"
            label-for="telephone"
          >
            <b-form-input
              id="telephone"
              v-model="form.address.telephone"
              type="text"
              v-on:blur="validatePhone()"
              :state="form.address.telephoneState"
              aria-describedby="address.telephone-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="address.telephone-feedback">
              {{ form.address.telephoneError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            v-if="showFullAddress"
            class="account-inputs"
            id="postcode-group1"
            :label="$t('form_postal_code') + '*'"
            label-for="postcode1"
          >
            <b-form-input
              id="postcode1"
              v-model="form.address.postcode"
              type="text"
              v-on:blur="validatePostcode()"
              :state="form.address.postcodeState"
              aria-describedby="address.postcode1-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="address.postcode1-feedback">
              {{ form.address.postcodeError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if="showFullAddress"
            class="account-inputs"
            id="street-group"
            :label="$t('form_street') + '*'"
            label-for="street"
          >
            <b-form-input
              id="street"
              v-model="form.address.streetDisplay"
              type="text"
              v-on:blur="validateStreet()"
              :state="form.address.streetDisplayState"
              aria-describedby="address.streetDisplay-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="address.streetDisplay-feedback">
              {{ form.address.streetDisplayError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if="showFullAddress"
            class="account-inputs"
            id="city-group"
            :label="$t('form_city') + '*'"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="form.address.city"
              type="text"
              v-on:blur="validateCity()"
              :state="form.address.cityState"
              aria-describedby="address.city-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="address.city-feedback">
              {{ form.address.cityError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            class="account-inputs"
            id="country-group"
            :label="$t('form_country') + '*'"
            label-for="country"
          >
            <b-form-select
              id="country"
              v-model="form.address.country_code"
              :options="countries"
              class="select"
            ></b-form-select>
          </b-form-group>
          <b-form-checkbox
            id="default_billing"
            v-model="form.address.default_billing"
            name="default_billing"
            >{{ $t("form_default_billing") }}</b-form-checkbox
          >
          <b-form-checkbox
            id="default_shipping"
            v-model="form.address.default_shipping"
            name="default_shipping"
            >{{ $t("form_default_shipping") }}</b-form-checkbox
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <div class="form-footer-wrap">
            <b-button @click="submit" variant="primary" class="">{{
              $t("save")
            }}</b-button>
            <b-link @click="cancel" class="form-back-btn">
              <b-icon icon="chevron-left"></b-icon>
              <span>{{ $t("back_to_overview") }}</span>
            </b-link>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { Logger } from "@storefront/core/lib/logger";
import config from "@config";

export default {
  name: "AccountEditAddress",
  computed: {
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    editAddressId: {
      get() {
        return this.$store.getters["user/getEditAddressId"];
      },
      set(val) {
        this.$store.commit("user/setEditAddressId", val);
      },
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    countries() {
      return this.$store.getters["user/getCountries"];
    },
    country() {
      return this.form.address.country_code;
    },
    postcodeHouseNumber() {
      return `${this.form.address.postcode}|${this.form.address.house_number}`;
    },
    vatNumber() {
      return this.form.taxvat;
    },
  },
  mounted() {
    if (this.editAddressId != null) {
      const address = this.$store.getters["user/getAddressByID"](
        this.editAddressId
      );
      if (address.company != null) {
        this.isCompany = true;
      } else {
        this.isCompany = false;
      }
      this.form.company = address.company;
      this.form.address.country_code = address.country_code;
      this.form.firstname = address.firstname;
      this.form.lastname = address.lastname;
      this.form.address.streetDisplay = address.street[0];
      this.form.address.postcode = address.postcode;
      this.form.address.city = address.city;
      this.form.address.telephone = address.telephone;
      this.form.address.default_billing = address.default_billing;
      this.form.address.default_shipping = address.default_shipping;
      if (address.vat_id != null) {
        this.form.taxvat = address.vat_id;
      } else {
        this.form.taxvat = "";
      }
    }
  },
  data() {
    return {
      isCompany: true,
      thanksActive: false,
      showFullAddress: true,
      showManual: true,
      typeTimer: null,
      foundAddresses: [],
      foundAddress: "",
      selectedAddress: null,
      isValidVatNumber: false,
      showVatMessage: "",
      showVatCheck: false,

      form: {
        company: "",
        companyState: null,
        companyError: "",
        companyTimer: null,
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        firstnameTimer: null,
        lastname: "",
        lastnameState: null,
        lastnameError: "",
        lastnameTimer: null,
        taxvat: "",
        taxvatState: null,
        taxvatError: "",
        taxvatTimer: null,

        address: {
          id: null,
          country_code: "",
          postcode: "",
          postcodeState: null,
          postcodeError: "",
          postcodeTimer: null,

          street: "",
          streetDisplay: "",
          streetDisplayState: null,
          streetDisplayError: "",
          streetDisplayTimer: null,

          addition: "",
          city: "",
          cityState: null,
          cityError: "",
          cityTimer: null,

          telephone: "",
          telephoneState: null,
          telephoneError: "",
          telephoneTimer: null,
          default_billing: true,
          default_shipping: true,
        },
        checkbox2: false,
        manual: true,
      },
    };
  },
  methods: {
    async submit() {
      let formValid = true;
      // if (this.isCompany == true) {
      //   if (this.form.companyState != true) {
      //     this.validateCompany();
      //     if (this.form.companyState != true) {
      //       formValid = false;
      //     }
      //   }
      //    Logger.debug("formSubmit", "company", formValid)();
      //   if (this.form.coc_numberState != true) {
      //     this.validateCOCNumber();
      //     if (this.coc_numberState != true) {
      //       formValid = false;
      //     }
      //   }
      //   Logger.debug("formSubmit", "coc_number", formValid)();
      //   if (this.form.taxvatState != true) {
      //     this.validateVAT();
      //     if (this.taxvatState != true) {
      //       formValid = false;
      //     }
      //   }
      //   Logger.debug("formSubmit", "taxvat", formValid)();
      // }
      if (this.form.firstnameState != true) {
        this.validateFirstname();
        Logger.debug("formSubmit", "fistname1", this.firstnameState)();
        if (this.form.firstnameState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "fistname", formValid)();
      if (this.form.lastnameState != true) {
        this.validateLastname();
        if (this.form.lastnameState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "lastname", formValid)();
      if (this.form.address.postcodeState != true) {
        this.validatePostcode();
        if (this.form.address.postcodeState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "postcode", formValid)();
      if (this.form.address.streetDisplayState != true) {
        this.validateStreet();
        if (this.form.address.streetDisplayState != true) {
          formValid = false;
          this.showFullAddress = true;
        }
      }
      Logger.debug("formSubmit", "street", formValid)();
      if (this.form.address.telephoneState != true) {
        this.validatePhone();
        if (this.form.address.telephoneState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "telephone", formValid)();
      if (this.form.address.cityState != true) {
        this.validateCity();
        if (this.form.address.cityState != true) {
          formValid = false;
        }
      }
      if (formValid == true) {
        const address = {};
        if (this.editAddressId > 0) {
          address.id = this.editAddressId;
        }
        address.company = this.form.company;
        address.country_code = this.form.address.country_code;
        address.firstname = this.form.firstname;
        address.lastname = this.form.lastname;
        address.street = this.form.address.streetDisplay;
        address.postcode = this.form.address.postcode;
        address.city = this.form.address.city;
        address.telephone = this.form.address.telephone;
        address.vat_id = this.form.taxvat;
        address.default_billing = this.form.address.default_billing;
        address.default_shipping = this.form.address.default_shipping;
        if (this.isCompany != true) {
          address.company = "";
          address.vat_id = "";
        }
        if (this.editAddressId > 0) {
          const retval = await this.$store.dispatch("user/updateAddress", {
            address: address,
          });
          if (retval == true) {
            const msg = {
              type: "success",
              title: this.$t("account"),
              text: this.$t("address_modified"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            this.editAddressId = 0;
          }
        } else if (this.editAddressId == -1) {
          const retval = await this.$store.dispatch("user/addAddress", {
            address: address,
          });
          if (retval == true) {
            const msg = {
              type: "success",
              title: this.$t("account"),
              text: this.$t("address_modified"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            this.editAddressId = 0;
          }
        }
      }
    },
    cancel() {
      this.editAddressId = 0;
    },
    // async validateVAT() {
    //   if (this.isCompany == true) {
    //     if (this.form.taxvat != "") {
    //       if (
    //         this.form.taxvat.substring(0, 2).toUpperCase() ===
    //         this.form.address.country_code
    //       ) {
    //         const retval = await validateVatNumber(this.form.taxvat);
    //         Logger.debug("vatcheck", "validateVat", retval.valid)();
    //         if (retval.valid == "true") {
    //           this.isValidVatNumber = true;
    //           this.form.taxvatState = true;
    //         } else {
    //           this.isValidVatNumber = false;
    //           this.form.taxvatState = false;
    //           this.form.taxvatError = this.$t("taxvat_invalid");

    //           this.showVatMessage = this.$t("taxvat_invalid");
    //         }
    //       } else {
    //         this.isValidVatNumber = false;
    //         this.form.taxvatState = false;
    //         this.form.taxvatError = this.$t("taxvat_wrong_country");
    //       }
    //     } else {
    //       this.form.taxvatState = null;
    //       this.form.taxvatError = "";
    //     }
    //   } else {
    //     this.form.taxvatState = null;
    //   }
    // },
    // validateCompany() {
    //   if (this.isCompany == true) {
    //     Logger.debug(
    //       "this.form.company",
    //       "validateCompany",
    //       this.form.company
    //     )();
    //     if (this.form.company == "" || this.form.company == null) {
    //       this.form.companyState = false;
    //       this.form.companyError = this.$t("company_name_required");
    //     } else {
    //       this.form.companyState = true;
    //     }
    //   } else {
    //     this.form.companyState = null;
    //   }
    // },
    validateFirstname() {
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
      }
    },
    validateLastname() {
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
      }
    },
    validateStreet() {
      if (this.form.address.streetDisplay == "") {
        this.form.address.streetDisplayState = false;
        this.form.address.streetDisplayError = this.$t("street_required");
      } else {
        this.form.address.streetDisplayState = true;
      }
    },
    validateCity() {
      if (this.form.address.city == "") {
        this.form.address.cityState = false;
        this.form.address.cityError = this.$t("city_required");
      } else {
        this.form.address.cityState = true;
      }
    },
    validatePhone() {
      if (this.form.address.telephone == "") {
        this.form.address.telephoneState = false;
        this.form.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.address.telephoneState = true;
      }
    },
    validatePostcode() {
      const nl = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
      const de = /(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})/g;
      const be = /^[1-9][0-9]{3}$/g;
      if (
        this.form.address.country_code == "NL" &&
        nl.test(this.form.address.postcode)
      ) {
        this.form.address.postcodeState = true;
      } else if (
        this.form.address.country_code == "BE" &&
        be.test(this.form.address.postcode)
      ) {
        this.form.address.postcodeState = true;
      } else if (
        this.form.address.country_code == "DE" &&
        de.test(this.form.address.postcode)
      ) {
        this.form.address.postcodeState = true;
      } else {
        this.form.address.postcodeState = false;
        this.form.address.postcodeError = this.$t("postcode_required");
      }
    },
  },
  watch: {
    country() {
      // if (config.postcode_validation.enabled) {
      //   if (!config.postcode_validation.countries.includes(this.country)) {
      //     this.showFullAddress = true;
      //   } else {
      //     this.showFullAddress = false;
      //   }
      // } else {
      //   this.showFullAddress = true;
      // }
    },
    vatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.taxvatTimer !== null) {
          clearTimeout(this.form.taxvatTimer);
        }
        this.form.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateVAT();
          },
          1000,
          this
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>