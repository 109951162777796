<template>
  <div class="account-my-account">
    <div class="wish-list-slider">
      <div class="title no-border">
        <h3>{{ $t("wishlist") }}</h3>
      </div>
      <ProductSlider
        v-if="wishlistItems.length > 0"
        :productList="wishlistItems"
        :productToShow="3"
        :sliderResponsive="[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 540,
            settings: {
              slidesToShow: 1,
            },
          },
        ]"
      />
    </div>
    <div class="address-block main-address">
      <div class="address-info">
        <h3>{{ $t("contact_information") }}</h3>
        <div class="user-info-wrap">
          <div class="user-info">
            <label>{{ user.firstname }} {{ user.lastnamename }}</label>
            <span>{{ user.email }}</span>
          </div>
          <div class="user-info-action">
            <b-link class="btn btn-gray" @click="goToEditAccount">{{
              $t("edit_address")
            }}</b-link
            ><b-link class="btn btn-gray" @click="goToEditPassword">{{
              $t("change_password")
            }}</b-link>
          </div>
        </div>
      </div>
      <div class="newsletter-info">
        <h3>{{ $t("newsletters") }}</h3>
        <div class="wrap">
          <label>{{ $t("modify_newsletters") }}</label>
          <b-link class="btn btn-gray" @click="goToEditSubscription">{{
            $t("register")
          }}</b-link>
        </div>
      </div>
    </div>
    <div class="address-block manage-address">
      <h3>
        {{ $t("directory") }}
      </h3>
      <b-row>
        <b-col sm="6" md="6" lg="4">
          <div class="single-address">
            <div class="single-address-inner">
              <h5>{{ $t("default_billing_address") }}</h5>
              <div class="no_info" v-if="defaultBilling == null">
                {{ $t("no_default_billing_address") }}
              </div>
              <address v-if="defaultBilling != null">
                <span
                  >{{ defaultBilling.firstname }}
                  {{ defaultBilling.lastname }}</span
                >
                <span v-if="defaultBilling.company != null">{{
                  defaultBilling.company
                }}</span>
                <span>{{ defaultBilling.street[0] }}</span>
                <span
                  >{{ defaultBilling.city }},
                  {{ defaultBilling.postcode }}</span
                >
                <span>{{ defaultBilling.country_code }}</span>
                <span
                  >T:<a :href="`tel:` + defaultBilling.telephone">
                    {{ defaultBilling.telephone }}</a
                  ></span
                >
                <span v-if="defaultBilling.vat_id != null"
                  >VAT:{{ defaultBilling.vat_id }}</span
                >
              </address>
            </div>
            <div class="wrap">
              <b-link
                class="link"
                @click="goToEditAddress(defaultBilling.id)"
                >{{ $t("modify") }}</b-link
              >
              <b-link class="remove"> <b-icon icon="trash"></b-icon></b-link>
            </div>
          </div>
        </b-col>

        <b-col sm="6" md="6" lg="4">
          <div class="single-address">
            <div class="single-address-inner">
              <h5>{{ $t("default_delivery_address") }}</h5>
              <div class="no_info" v-if="defaultShipping == null">
                {{ $t("default_delivery_address") }}
              </div>
              <address v-if="defaultShipping != null">
                <span
                  >{{ defaultShipping.firstname }}
                  {{ defaultShipping.lastname }}</span
                >
                <span v-if="defaultShipping.company != null">{{
                  defaultShipping.company
                }}</span>
                <span>{{ defaultShipping.street[0] }}</span>
                <span
                  >{{ defaultShipping.city }},
                  {{ defaultShipping.postcode }}</span
                >
                <span>{{ defaultShipping.country_code }}</span>
                <span
                  >T:<a :href="`tel:` + defaultShipping.telephone">
                    {{ defaultShipping.telephone }}</a
                  ></span
                >
                <span v-if="defaultShipping.vat_id != null"
                  >VAT:{{ defaultShipping.vat_id }}</span
                >
              </address>
            </div>
            <div class="wrap">
              <b-link
                class="link"
                @click="goToEditAddress(defaultShipping.id)"
                >{{ $t("modify") }}</b-link
              >
              <b-link class="remove"> <b-icon icon="trash"></b-icon></b-link>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ProductSlider from "@/base/core/components/products/product-detail/ProductSlider";

export default {
  name: "AccountMyAccount",
  components: {
    ProductSlider,
  },

  data() {
    return {};
  },
  computed: {
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    wishlistItems() {
      const items = this.$store.getters["user/getWishlist"];
      return items.map((item) => item.product);
    },
    defaultBilling() {
      return this.$store.getters["user/getDefaultBillingAddress"];
    },
    defaultShipping() {
      return this.$store.getters["user/getDefaultShippingAddress"];
    },
  },
  methods: {
    goToEditAccount() {
      this.tabIndex = 4;
    },
    goToEditPassword() {
      this.tabIndex = 6;
    },
    goToEditAddress(id) {
      this.tabIndex = 3;
      this.$store.commit("user/setEditAddressId", id);
    },
    goToManageAddress() {
      this.tabIndex = 3;
    },
    goToEditSubscription() {
      this.tabIndex = 7;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>